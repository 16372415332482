import * as React from "react";
import * as Redux from "react-redux";
import { Grid, Paper, Typography, Box } from "@mui/material";
import Lottie from "lottie-react";
import working from "../../static/lottie/animation_ll332nmy.json";
import { Stack } from "@mui/material";
import { MORADO_COLOR } from "../../constants/ColorsConst";
import { ParticlesComponent } from "../particles/Particles";

const title = (text) => (
  <Typography
    sx={{
      fontSize: {
        xs: "18pt",
        md: "1.5em",
      },
      fontWeight: "bold",
      my: 1.5,
    }}
  >
    {text}
  </Typography>
);
const subtitle = (text) => (
  <Typography
    sx={{
      fontSize: "1.2em",
      fontWeight: "bold",
      my: 1.5,
      ml: "40px",
    }}
  >
    {text}
  </Typography>
);

const text = (v) => (
  <Typography
    sx={{
      fontSize: "1em",
      mb: 1,
      textIndent: "40px",
    }}
  >
    {v}
  </Typography>
);

const vineta = (v) => (
  <Typography
    sx={{
      fontSize: "1em",
      mb: 1,
      ml: "40px",
    }}
  >
    {v}
  </Typography>
);

const PoliticasView = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} sx={{ m: 4 }}>
        <center>
          <img src="./img/logo2.png" style={{ width: "200px" }} />
        </center>
        <Typography
          sx={{
            fontSize: "1em",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          By{" "}
          <img
            src="./img/logo.svg"
            style={{
              width: "80px",
              marginLeft: "5px",
              filter: MORADO_COLOR,
            }}
          />
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: "24pt",
              md: "3em",
            },
            fontWeight: "bold",
            textAlign: "center",
            mt: 2,
          }}
        >
          Políticas de Privacidad y Términos y condiciones de la aplicación
          móvil
        </Typography>
        {title("Recogida y tratamiento de datos de carácter personal")}
        {text(
          "Los datos de carácter personal son los que pueden ser utilizados para identificar a una persona o ponerse en contacto con ella."
        )}
        {text(
          "ON PASS puede solicitar datos personales de usuarios al acceder a aplicaciones de la empresa o de otras empresas afiliadas, así como la posibilidad de que entre estas empresas puedan compartir esos datos para mejorar los productos y servicios ofrecidos. Si no se facilitan esos datos personales, en muchos casos no podremos ofrecer los productos o servicios solicitados."
        )}
        {text(
          "Estos son algunos ejemplos de las categorías de datos de carácter personal que ON PASS puede recoger y la finalidad para los que puede llevar a cabo el tratamiento de estos datos."
        )}
        {title("¿Qué datos de carácter personal se pueden recopilar?")}
        {vineta(
          "- Al crear un ID, solicitar un crédito comercial, comprar un producto, descargar una actualización de software, se recopilan diferentes datos, como nombre dirección postal, número de teléfono, dirección de correo electrónico o los datos de la tarjeta de crédito."
        )}
        {vineta(
          "- Cuando se comparten contenidos con familiares y amigos o se invita a otras personas a participar en los servicios o foros, pueden recogerse los datos que facilitamos sobre esas personas, como su nombre, domicilio, correo electrónico, y número de teléfono. Se utilizarán dichos datos para completar sus pedidos, mostrarle el producto o servicio correspondiente o para combatir el fraude."
        )}
        {title("Propósito de tratamiento de datos de carácter personal")}
        {text("ON PASS podrá utilizar los datos personales recabados para:")}
        {vineta(
          "- Los datos de carácter personal recopilados permiten mantenerle informado acerca de los últimos productos, las actualizaciones de software disponibles y los próximos eventos."
        )}
        {vineta(
          "- También se utilizan los datos de carácter personal como ayuda para elaborar, perfeccionar, gestionar, proporcionar y mejorar los productos, servicios, contenidos y publicidad, y con el propósito de evitar pérdidas y fraudes."
        )}
        {vineta(
          "- Puede utilizarse los datos de carácter personal para comprobar la identidad, colaborar en la identificación de usuarios y decidir los servicios apropiados."
        )}
        {vineta(
          "- También se utilizan esos datos de carácter personal con propósitos internos, incluyendo auditorías, análisis de datos y sondeos, para mejorar los productos, servicios y comunicaciones a clientes."
        )}
        {vineta(
          "- Si participa en un sorteo, un concurso o una promoción, pueden usarse los datos proporcionados para administrar estos programas."
        )}
        {title("Recopilación y tratamiento de datos de carácter no personal")}
        {text(
          "ON PASS también recopilará datos de un modo que, por sí mismos, no pueden ser asociados directamente a una persona determinada. Estos datos de carácter no personal se pueden recopilar, tratar, transferir, y publicar con cualquier intención. Estos son algunos ejemplos de las clases de datos de carácter no personal que ON PASS puede recopilar y los fines para los que se realiza su tratamiento:"
        )}
        {vineta(
          "- Datos tales como profesión, idioma, código postal, identificador único de dispositivo, etc. para comprender mejor la conducta de nuestros clientes y mejorar nuestros productos, servicios y anuncios publicitarios."
        )}
        {vineta(
          "- Datos sobre cómo se usan determinados servicios, incluidas las consultas de búsqueda. Esta información se puede utilizar para incrementar la importancia de los resultados que aportan los servicios ofrecidos."
        )}
        {vineta(
          "- Datos sobre cómo usa su dispositivo y las aplicaciones para facilitar a los desarrolladores la mejora de esas aplicaciones."
        )}
        {text(
          "Si juntamos datos de carácter no personal con datos personales, los datos mezclados serán tratados como datos personales mientras sigan estando combinados."
        )}
        {title("Divulgación a terceros")}
        {text(
          "Ocasionalmente ON PASS puede facilitar determinados datos de carácter personal a socios estratégicos que trabajen con nosotros para proveer productos y servicios o nos ayudan en nuestras actividades de marketing. No se compartirán los datos con ningún tercero para sus propios fines de marketing."
        )}
        {subtitle("Proveedores de servicios")}
        {text(
          "ON PASS compartirá datos de carácter personal con empresas que se ocupan, entre otras actividades, de prestar servicios de tratamiento de datos, conceder créditos, tramitar pedidos de clientes, presentar sus productos, mejorar datos de clientes, suministrar servicios de atención al cliente, evaluar su interés en productos y servicios y realizar investigaciones sobre clientes o su grado de satisfacción."
        )}
        {subtitle("Otros terceros")}
        {text(
          "Es posible que ON PASS divulgue datos de carácter personal por mandato legal, en el marco de un proceso judicial o por petición de una autoridad pública, tanto dentro como fuera de su país de residencia. Igualmente se puede publicar información personal si es necesaria o conveniente por motivos de seguridad nacional, para acatar la legislación vigente o por otras razones relevantes de orden público."
        )}
        {title("Protección de datos de carácter personal")}
        {text(
          "ON PASS garantizará la protección de los datos personales mediante cifrado durante el tránsito y, los alojados en instalaciones, con medidas de seguridad físicas."
        )}
        {text(
          "Al usar ciertos productos, servicios o aplicaciones o al publicar opiniones en foros, salas de chat o redes sociales, el contenido y los datos de carácter personal que se comparta serán visible para otros usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos. Usted será responsable de los datos de carácter personal que distribuya o proporcione en estos casos."
        )}
        {subtitle("Integridad y conservación de datos de carácter personal")}
        {text(
          "ON PASS garantizará la exactitud y la calidad de los datos personales, se conservarán durante el tiempo necesario para cumplir los fines para los que fueron recabados, salvo que la ley exija conservarlos durante más tiempo."
        )}
        {subtitle("Acceso a los datos de carácter personal")}
        {text(
          "Respecto a los datos de carácter personal que conservamos, ON PASS le ofrece acceso a ellos para cualquier fin, incluyendo las solicitudes de rectificación en caso de que sean incorrectos o de eliminación en caso de no estar obligados a conservarlos por imperativo legal o por razones legítimas de negocio. Nos reservamos el derecho a no tramitar aquellas solicitudes que sean improcedentes o vejatorias, que pongan en riesgo la privacidad de terceros, que resulten inviables o para las que la legislación local no exija derecho de acceso. Las solicitudes de acceso, rectificación o eliminación podrán enviarse a nuestra dirección Av. Rómulo Gallegos, Centro Aloa, PB, Local 5-C y 5-D, Horizonte, Caracas, Venezuela o en la cuenta de correo electrónico info@onsolution.app."
        )}
        {title("Niños y educación")}
        {text(
          "ON PASS es consciente de la necesidad de establecer precauciones adicionales para preservar la privacidad y la seguridad de los menores que utilizan las aplicaciones y exigir consentimiento de sus progenitores y/o responsables en caso de que no tengan la edad mínima exigida por la legislación."
        )}
        {text(
          "Si se han recopilado datos personales de un menor, sin el consentimiento necesario, se debe eliminar esa información lo antes posible."
        )}
        {title("Servicios de localización")}
        {text(
          "Para prestar servicios de localización ON PASS podrá reunir, utilizar y compartir datos exactos sobre ubicaciones, incluyendo la situación geográfica en tiempo real de su ordenador o de su dispositivo. Salvo que nos den su consentimiento, estos datos de localización se recogen de manera anónima de forma que no pueden utilizarse para identificarlo personalmente, y son usados para suministrar y mejorar sus productos y servicios de localización."
        )}
        {title("Página web y servicios de terceros")}
        {text(
          "Las aplicaciones pueden contener enlaces a páginas web, productos y servicios de terceros. También pueden utilizar u ofrecer productos o servicios de terceros. La recogida de datos por parte de terceros, introduciendo de datos sobre ubicaciones geográficas o datos de contacto, se guiará por sus respectivas políticas de privacidad. Le recomendamos consultar las políticas de privacidad de esos terceros."
        )}
      </Grid>
    </Grid>
  );
};

export default PoliticasView;
