// import * as SVG from "../constants/SvgConst"
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import ChairIcon from "@mui/icons-material/Chair";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CategoryIcon from "@mui/icons-material/Category";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import HouseIcon from "@mui/icons-material/House";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import ContactsIcon from "@mui/icons-material/Contacts";
import AodIcon from "@mui/icons-material/Aod";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import { ReactComponent as SvgRecibos } from '../static/svg/pagos_icon.svg'

/*========================================================
/ Dashboard
/======================================================== */
export const inicio = {
  title: "Inicio",
  icon: <DashboardCustomizeRoundedIcon />,
  to: "/",
};
export const politicas = {
  title: "Politicas y Privacidad",
  icon: <DashboardCustomizeRoundedIcon />,
  to: "/politicas",
};
