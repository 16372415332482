import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Router from "./router/Router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/Store";
// import './locales/i18n'
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";

// Configura la hora de Venezuela
export const TIMEZONE = "America/Caracas";
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
dayjs.tz.setDefault(TIMEZONE);

const theme = createTheme({
  palette: {
    primary: {
      main: purple[700],
    },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div>Loading</div>}>
          <Router />
        </Suspense>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// https://github.com/azizmashkour/react-i18n/blob/master/src/App.js
