import * as Redux from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Toolbar } from "@mui/material";
import { drawerWidth } from "../drawer/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ButtonProfile from "./ButtonProfile";
import { MORADO_COLOR } from "../../../constants/ColorsConst";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarTemplate = ({ open, toggleDrawer }) => {
  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{ backgroundColor: MORADO_COLOR }}
    ></AppBar>
  );
};

export default AppBarTemplate;
