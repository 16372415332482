import * as ROUTES from "../router/Routes";

export const OWNER = "owner";
export const SECURITY = "security";
export const ENTITY = "entity";
export const FAMILY = "family";
export const NANNY = "nanny";
export const SERVICE = "service";
export const DRIVER = "driver";
export const CLIENT = "client";
export const ADMIN = "admin";
export const OTHER = "other";

export const DAY = "day";
export const HOUR_BLOCK = "hour_block";

export const STATUS_ENUM = {
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  REJECTED: "REJECTED",
  TO_CHECK: "TO_CHECK",
};

export const CODE_TYPE_ENUM = {
  [OWNER]: "Propietario",
  [SECURITY]: "Seguridad",
  [ENTITY]: "Entidad",
  [FAMILY]: "Familiar",
  [NANNY]: "Niñera",
  [SERVICE]: "Trabajador doméstico",
  [DRIVER]: "Chofer",
  [CLIENT]: "Cliente",
  [ADMIN]: "Admin",
  [OTHER]: "Otro",
};

export const TYPE_ENUM = {
  [ADMIN]: "Admin",
  [CLIENT]: "Cliente",
};

export const TIPO_RESERVA_ENUM = {
  [HOUR_BLOCK]: "Bloques",
  [DAY]: "Todo el día",
};

export const GENDER_ENUM = {
  male: "Masculino",
  female: "Femenino",
  other: "Otro",
  unspecified: "Sin Específicar",
};

export const TIPOS_PAGOS_ENUM = {
  debt: "DEUDA",
  pay: "Pagado",
};

export const TIPOS_DOCUMENTOS_ENUM = {
  maintenance: "Mantenimiento",
  administrative: "Administrativo",
  rules: "Reglamento",
  regulations: "Normativa",
  communications: "Comunicado",
};

export const MODULOS_ENUM = {
  Contacts: "Contactos",
  Control: "Accesos",
  Documents: "Documentos",
  Emergencies: "Emergencias",
  History: "Historial",
  Invitations: "Invitaciones",
  Payments: "Recibos",
  Profiles: "Perfil",
  Reserves: "Reservas",
  Scanner: "Escanner",
  Services: "Servicios",
};

export const TYPE_ENTITY_ENUM = {
  home: "Residencia",
  residence: "Residencia",
  club: "Club",
  office: "Oficina",
};
