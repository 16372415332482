import * as React from "react";
import * as Redux from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Divider, IconButton, List, Toolbar } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiDrawer from "@mui/material/Drawer";
import ItemDrawer from "./ItemDrawer";
import * as ROUTES from "../../../router/Routes";
import { ADMIN, ONPASS, ROL, SU } from "../../../constants/ConfigConst";
import LogOnComponent from "./Logon";
import InfoEntidadComponent from "./InfoEntidad";
import { AZUL_COLOR, MORADO_COLOR } from "../../../constants/ColorsConst";
import { grey } from "@mui/material/colors";

export const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerTemplate = ({ open, toggleDrawer }) => {
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const entities = loginStore.entities;
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  const isAdmin = loginStore.roles.indexOf(ADMIN) !== -1;
  const isActivoEntidad = entidadesStore.activo !== null && (isRoot || isAdmin);

  const lista = <List component="nav" sx={{ width: "100%", mx: 0.5 }}></List>;

  return (
    <Drawer variant="permanent" anchor="left" open={open}>
      <Box>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <InfoEntidadComponent />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
      </Box>
      <Box
        sx={{
          display: "flex",
          overflowX: "hidden",
          overflowY: "auto",
          position: "relative",
          minHeight: "75%",
          "::-webkit-scrollbar": {
            width: "7px",
          },
          "::-webkit-scrollbar-track": {
            background: grey[300] /* color of the tracking area */,
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: MORADO_COLOR /* color of the scroll thumb */,
            borderRadius: "4px" /* roundness of the scroll thumb */,
          },
        }}
      >
        {lista}
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "15%",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <LogOnComponent open={open} isLogo />
      </Box>
    </Drawer>
  );
};

export default DrawerTemplate;
