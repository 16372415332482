export const GET_DASHBOARD = "GET_DASHBOARD";
export const LOADING_DASHBOARD = "LOADING_DASHBOARD";

const initialState = {
  inicio: null,
  loading: false,
};

export const inicioReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
