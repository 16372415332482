import * as React from "react";
import * as Redux from "react-redux";
import { Grid, Paper, Typography, Box, Button, Link } from "@mui/material";
import Lottie from "lottie-react";
import working from "../../static/lottie/animation_ll332nmy.json";
import { Stack } from "@mui/material";
import { MORADO_COLOR } from "../../constants/ColorsConst";
import { ParticlesComponent } from "../particles/Particles";

const InicioView = () => {
  return (
    <>
      <ParticlesComponent />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Stack direction="column" alignItems="center">
            <Lottie animationData={working} />
            <Typography
              sx={{
                fontSize: "3em",
                fontWeight: "bold",
                position: "relative",
                bottom: "10vh",
              }}
            >
              Work in progress
            </Typography>
            <Typography
              sx={{
                fontSize: "1em",
                position: "relative",
                bottom: "10vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              By{" "}
              <img
                src="./img/logo.svg"
                style={{
                  width: "80px",
                  marginLeft: "5px",
                  filter: MORADO_COLOR,
                }}
              />
            </Typography>
            <Link
              href="/politicas"
              variant="body2"
              sx={{
                width: "100%",
                position: "absolute",
                bottom: "7vh",
                textAlign: "center",
              }}
            >
              Políticas de Privacidad y <br />
              Términos y condiciones de la aplicación móvil
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </>
  );
};

export default InicioView;
