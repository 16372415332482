import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as ROUTES from "./Routes";
import * as Redux from "react-redux";

import Template from "../components/template";

import { Inicio, Politicas } from "./Pages";

import { SU, TOKEN } from "../constants/ConfigConst";

const Routers = () => {
  return (
    <Router>
      <Switch>
        <PublicPage path="/" exact>
          <Inicio />
        </PublicPage>
        <PublicPage path={ROUTES.inicio.to} exact>
          <Inicio />
        </PublicPage>
        <PublicPage path={ROUTES.politicas.to} exact>
          <Politicas />
        </PublicPage>
      </Switch>
    </Router>
  );
};

const validarToken = () => {
  const token = localStorage.getItem(TOKEN);
  let isValid = false;
  try {
    isValid = token && token !== "";
  } catch (e) {
    return false;
  }
  return isValid;
};

const PublicPage = ({ children, ...props }) => {
  const inicioStore = Redux.useSelector((state) => state.inicio);

  const rol = inicioStore.rol;
  let isAuthenticated = validarToken();
  return !isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={rol === SU ? ROUTES.inicio.to : ROUTES.inicio.to} />
  );
};

const PrivatePage = ({ children, ...props }) => {
  let isAuthenticated = validarToken();
  return isAuthenticated ? (
    <Route {...props}> {children} </Route>
  ) : (
    <Redirect to={ROUTES.inicio.to} />
  );
};

export default Routers;
